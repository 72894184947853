<template>
	<draggable  v-model="$attrs.list"
                      handle=".listhandle"
                      list="list-items"
                      ghost-class="ghost"
                      tag="b-list-group" >
	    <b-list-group-item v-for="(slide, index) in $attrs.list" class="d-flex listhandle" @mouseenter="showHandle = index">
	    	<div class="">
		    	<div v-if="('image_url' in slide) && (showHandle !== index)">
		    		<img v-if="(slide[filterStylesKey(slide, 'image')].image.value !== '')" :src="slide[filterStylesKey(slide, 'image')].image.value" class="avatar-sm">
		    		<span v-else class="avatar-title">{{index + 1}}</span>
		    	</div>
		    	<div class="avatar-sm mr-2" v-else-if="showHandle !== index" >
		    		
		    		<span  class="avatar-title">{{index + 1}}</span>
		    	</div>
		    	<div v-else class="avatar-sm  mr-2">
	    			<a class="avatar-title listhandle text-white"  href="javascript"><i class="mdi mdi-cursor-move"></i></a>
	    		</div>
	    	</div>
	    	
	        <h5 class="my-auto font-size-14">{{filterStylesKey(slide, 'title') > -1 ? slide[filterStylesKey(slide, 'title')].title.value : slide[filterStylesKey(slide, 'author')].author.value}}</h5>
          		<a class="ml-auto my-auto btn btn-light text-primary mr-2" @click="$emit('edit-list-item', index)"><i class="bx bx-pencil"></i></a>
          		<a class="my-auto btn btn-light text-info mr-2" @click="$attrs.list.push(JSON.parse(JSON.stringify($attrs.list[index])))"><i class="mdi mdi-content-duplicate"></i></a>
	            <a class="my-auto btn btn-light text-danger" @click="$attrs.list.splice(index,1)"><i class="bx bx-trash-alt"></i></a>
	           
	     </b-list-group-item>
	     <b-list-group-item slot="footer" class="bg-light text-muted text-uppercase text-center" style="cursor: pointer" @click="$attrs.list.push(JSON.parse(JSON.stringify($attrs.list_item)))">
	                         				Add New Slide <i class="bx bx-plus"></i>
	    </b-list-group-item>
	</draggable>
</template>
<script type="text/javascript">
	import draggable from 'vuedraggable';

	export default {
		name: 'widgetContentList',
		data(){
			return {
				showHandle: -1
			}
		},
		components: { draggable },
		methods: {
			/*editItem(index){
				$emit('edit-list-item', index);
			},*/
			filterStylesKey(styles, key){
	       		for(var i = 0; i < styles.length; i++){
	       			 var keys = Object.keys(styles[i])
	       			 if(keys.includes(key)){
	       			 	 return i;
	       			 }
	       		}
       		},
		}
	}

</script>