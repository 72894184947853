<script>
	import Multiselect from "vue-multiselect";
	export default{
		components: { Multiselect },
		data(){
			return{
				id: [],
				options: [
					{id: 1, name: 'text'},
					{id: 2, name: 'text2'}
				],
				search: '',
				currentPage: 1,
				order: 'desc',
      			sortby: 'id',
      			limit: 0,
				limits: [
		        {value: 25, text: "25"},
		        {value: 50, text: "50"},
		        {value: 100, text: "100"},
		    	],
				response_error: []
			}
		},
		props: {
			marketplace_categories: {
				type: String,
				default: () => 'company'
			}
		},
		created(){
			axios.get('/api/company/listings?page='+this.currentPage+'&limit='+this.limit+'&sortby='+this.sortby+'&order='+this.order+'&search='+this.search+'', {headers: {'Authorization' : 'Bearer '+this.$attrs.user.api_token}}).then(response => {
				this.options = response.data;
				if(this.$attrs.id !== ''){
					for(var i = 0; i < this.$attrs.id.length; i++){
						var option = this.options.filter(item => item.id == this.$attrs.id[i]);
						this.id.push(option[0]);
					}
				}
			}).catch(error => {
				this.response_error = error.data;
			});

			//this.id = this.$attrs.id;
			
		},
		methods:{
			updateUser: function(selectedOption, id){
				this.$emit('location-selected', selectedOption.id);
				this.$emit('gplace-selected', selectedOption.gplace_id);
			},
			customLabel ({ title, desc }) {
		      return `${title} – ${desc}`
		    }
		}
	}
</script>
<template>
	<div>
	<multiselect v-model="id" track-by="name" label="name" :options="options" @select="updateUser" @remove="updateUser" :option-height="104" multiple >
                     <!--<template slot="singleLabel" slot-scope="{ option }">{{ option.name }} - {{ option.jobtitle }}</template>-->
                     <template slot="singleLabel" slot-scope="{ option }"><div class="d-flex w-100 my-auto"><div class="option__image avatar-sm mr-4 my-auto">
                        <img class="avatar-title rounded-circle" v-if="option.banner" :src="option.banner">
                        <img class="avatar-title rounded-circle" v-else src="/images/product-placeholder.png">
                      </div>
      <div class="option__desc d-flex flex-row w-100"><span class="option__title ml-2 my-auto">{{ option.name }}</span>
      </div>
  		</div></template>
    <template slot="option" slot-scope="{ option }">
    	<div class="d-flex w-100 my-auto"><div class="avatar-sm mr-4 my-auto">
                        <img class="avatar-title rounded-circle" v-if="option.banner" :src="option.banner">
                        <img class="avatar-title rounded-circle" v-else src="/images/product-placeholder.png">
                      </div>
      <div class="option__desc d-flex flex-row w-50"><span class="option__title ml-2 my-auto">{{ option.name }}</span>
      </div>
  		</div>
    </template>
                  </multiselect>
                  <pre class="language-json"><code>{{ value  }}</code></pre>
   </div>
</template>