<template>
	<b-row v-if="content[filterStylesKey(content, 'view')].view.value == 'grid'">
	  <b-col :class="'col-xl-'+content[filterStylesKey(content, 'columns')].columns.value.xl+' col-lg-'+content[filterStylesKey(content, 'columns')].columns.value.lg+' col-md-'+content[filterStylesKey(content, 'columns')].columns.value.md+' col-sm-'+content[filterStylesKey(content, 'columns')].columns.value.sm+' col-xs-'+content[filterStylesKey(content, 'columns')].columns.value.xs" v-for="image in content[filterStylesKey(content, 'list')].list" >
	       	<b-img-lazy :src="image.src" class="img-fluid" ></b-img-lazy>
	  </b-col>
	</b-row>
	<b-row v-else-if="content[filterStylesKey(content, 'view')].view.value == 'list'">
		 <b-col v-for="image in content[filterStylesKey(content, 'list')].list" md="12">
	       	<b-img-lazy :src="image.src" class="img-fluid" ></b-img-lazy>
	  	</b-col>
	</b-row>
	<b-row v-else-if="content[filterStylesKey(content, 'view')].view.value == 'masonry'">
		 <b-col v-for="image in content[filterStylesKey(content, 'list')].list">
	       	<b-img-lazy :src="image.src" class="img-fluid" ></b-img-lazy>
	  	</b-col>
	</b-row>
	<swiper v-else-if="(content[filterStylesKey(content, 'view')].view.value == 'carousel') && (contentReloading == false)" ref="imgGalleryCarousel" :options="swiperOptions" >
        <swiper-slide v-for="(image, index) in content[filterStylesKey(content, 'list')].list" class="h-100" >
              <b-img @click="selected_index = index" :src="image.src" class="border-radius-1em border img-fluid" :class="{'border border-primary' : index == selected_index}"></b-img>
        </swiper-slide>
  	</swiper>
  	<b-overlay :show="contentReloading" v-else-if="contentReloading == true" no-wrap></b-overlay>
</template>

<script type="text/javascript">
	import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
	import { EventBus } from "../../../../../app";
	export default {
		components: {
		    Swiper,
		    SwiperSlide
		  },
		  directives: {
		    swiper: directive
		  },
		data() {
	      return {
	      	contentReloading: false,
	      	hoverIndex: -1,
    		selected_index: -1,
      		storageurl: 'https://'+this.$attrs.company.nickname+'.s3.eu-west-2.amazonaws.com',
      		swiperOptions: {
                  direction: 'horizontal',
                  loop: true,
                  slidesPerView: 3,
                  spaceBetween: 10,
                  pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                  },
                  navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  },
                  breakpoints: {
		          0: {
		            slidesPerView: 1,
		            spaceBetween: 20
		          },
		          // when window width is >= 320px
		          350: {
		            slidesPerView: 1,
		            spaceBetween: 20
		          },
		          // when window width is >= 640px
		          768: {
		            slidesPerView: 2,
		            spaceBetween: 40
		          },
		          991: {
		            slidesPerView: 3,
		            spaceBetween: 40
		          },
		          1200: {
		            slidesPerView: 3,
		            spaceBetween: 40
		          },
		          1440: {
		            slidesPerView: 4,
		            spaceBetween: 40
		          }
        		}
            },
	      }
	    },
		props: {
			content: {
				type: Object
			}
		},
		computed: {
      swiper() {
        return this.$refs.imgGalleryCarousel.$swiper
      }
    },
    watch: {
		 	$route(){
		 		this.$nextTick(() => {
			 		this.compileSlides();
            	});
		 	},
		},
    created(){
    	this.compileSlides();
    },
    mounted() {
	    console.log('Current Swiper instance object', this.swiper);
	      //this.compileSlides();
	      this.swiper.slideTo(this.startSlide, 1000, false);

	      let self = this;
             EventBus.$on('update-widget', function(){
                    self.compileSlides();
                    self.contentReloading = true;
                    setTimeout(function(){
                        self.contentReloading = false;
                    }, 1000);
             });
	  },
	  methods: {
		    filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
	        compileSlides(){
				var sizes = Object.keys(this.$attrs.page);
                for(var i = 0; i < sizes.length; i++){
                	console.log('Slide '+i+ ':'+sizes[i]);
                	var slide = sizes[i];

                					if(slide == 'xs'){
										this.swiperOptions.breakpoints[0].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}else if(slide == 'sm'){
										this.swiperOptions.breakpoints[350].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}else if(slide == 'md'){
										this.swiperOptions.breakpoints[768].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}else if(slide == 'lg'){
										this.swiperOptions.breakpoints[991].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);				
									}else if(slide =  'xl'){
										this.swiperOptions.breakpoints[1200].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
										this.swiperOptions.breakpoints[1440].slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);	
										this.swiperOptions.slidesPerView = parseFloat(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent[this.filterStylesKey(this.$attrs.page[slide].sections[this.$attrs.block_array[0]].rows[this.$attrs.block_array[1]].cols[this.$attrs.block_array[2]].blocks[this.$attrs.block_array[3]].widgetcontent, this.content[this.filterStylesKey(this.content, 'view')].view.value.replaceAll(' ', '_'))].carousel.subfields, 'show')].show.value).toFixed(0);
									}
                }
                this.swiper.update();
			},
		}
	}

</script>