<template>
	<div :style="compileElementStyles($attrs.content, 'horizontal_alignment', ['horizontal_alignment'])">
		<div class="d-inline-block position-relative" >
	
		<div :style="'background: url(' + $attrs.content[filterStylesKey($attrs.content, 'url')].url.value.replaceAll(' ', '%20') + '); background-size: cover; width: ' + $attrs.content[filterStylesKey($attrs.content, 'width')].width.value+$attrs.content[filterStylesKey($attrs.content, 'width')].width.units + ';  height: ' + $attrs.content[filterStylesKey($attrs.content, 'height')].height.value+$attrs.content[filterStylesKey($attrs.content, 'height')].height.units + ';' + compileElementStyles($attrs.content, 'image', ['border'])" ><b-img-lazy   :src="$attrs.content[filterStylesKey($attrs.content, 'url')].url.value.replaceAll(' ', '%20')" :style="'width: ' + $attrs.content[filterStylesKey($attrs.content, 'width')].width.value+$attrs.content[filterStylesKey($attrs.content, 'width')].width.units + ';  height: ' + $attrs.content[filterStylesKey($attrs.content, 'height')].height.value+$attrs.content[filterStylesKey($attrs.content, 'height')].height.units + ';' + compileElementStyles($attrs.content, 'image', ['border'])" :class="[{'img-fluid' : $attrs.content[filterStylesKey($attrs.content, 'fullwidth')].fullwidth.value == true}, {'hide' : $attrs.elements[filterStylesKey($attrs.elements, 'overlay')].overlay.value == true}]"></b-img-lazy></div>
		<div v-if="$attrs.elements[filterStylesKey($attrs.elements, 'overlay')].overlay.value == true"  class="position-absolute top-0 left-0 right-0 bottom-0 d-flex flex-column" :class="[$attrs.content[filterStylesKey($attrs.content, 'vertical_align')].vertical_align.value == 'center' ? 'justify-content-center' : '', $attrs.content[filterStylesKey($attrs.content, 'vertical_align')].vertical_align.value == 'top' ? 'justify-content-start' : '', $attrs.content[filterStylesKey($attrs.content, 'vertical_align')].vertical_align.value == 'bottom' ? 'justify-content-end' : '']" :style="compileElementStyles($attrs.elements, 'overlay_background', ['background', 'opacity', 'margin']) + compileElementStyles($attrs.content, 'image', ['border'])">
					
		</div>
		<div v-if="$attrs.elements[filterStylesKey($attrs.elements, 'overlay')].overlay.value == true"  class="position-absolute top-0 left-0 right-0 bottom-0 d-flex flex-column" :class="[$attrs.content[filterStylesKey($attrs.content, 'vertical_align')].vertical_align.value == 'center' ? 'justify-content-center' : '', $attrs.content[filterStylesKey($attrs.content, 'vertical_align')].vertical_align.value == 'top' ? 'justify-content-start' : '', $attrs.content[filterStylesKey($attrs.content, 'vertical_align')].vertical_align.value == 'bottom' ? 'justify-content-end' : '']" :style="compileElementStyles($attrs.elements, 'overlay_background', ['padding'])">
					<component :id="$attrs.id + '-overlay_title'" :is="$attrs.content[filterStylesKey($attrs.content, 'overlay_title')].overlay_title.subfields[filterStylesKey($attrs.content[filterStylesKey($attrs.content, 'overlay_title')].overlay_title.subfields, 'heading_level')].heading_level.value" :style="compileElementStyles($attrs.content, 'overlay_title', ['color', 'text_alignment'])">
						{{$attrs.elements[filterStylesKey($attrs.elements, 'overlay_title')].overlay_title.value}}
					</component>

					<p :id="$attrs.id + '-overlay_description'" :style="compileElementStyles($attrs.content, 'overlay_description', ['color', 'text_alignment'])">
						{{$attrs.elements[filterStylesKey($attrs.elements, 'overlay_description')].overlay_description.value}}
					</p>

					
		</div>
		<a v-if="($attrs.elements[filterStylesKey($attrs.elements, 'link')].link.value !== '') && ($attrs.liveMode == false)"  class="position-absolute top-0 left-0 right-0 bottom-0 d-flex flex-column" :href="'#'">
		</a>
		<router-link v-else-if="($attrs.elements[filterStylesKey($attrs.elements, 'link')].link.value !== '') && ($attrs.liveMode == true)"  class="position-absolute top-0 left-0 right-0 bottom-0 d-flex flex-column" :to="$attrs.elements[filterStylesKey($attrs.elements, 'link')].link.value" >
		</router-link>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
	      return {
	        mainProps: {
	          center: true,
	          fluidGrow: true,
	          blank: true,
	          blankColor: '#bbb',
	        }
	      }
	    },
	    methods: {
		    filterStylesKey(styles, key){
	            for(var i = 0; i < styles.length; i++){
	               var keys = Object.keys(styles[i])
	               if(keys.includes(key)){
	                 return i;
	               }
	            }
	        },
	        compiledStyles(styles){
	        	var style = 'will-change: auto; ';
	        	for(var i = 0; i < (styles.length - 1); i++ ){
	        		for(var key in styles[i] ){
	        			
	        		if(styles[i][key].active == true){
		        		if(key.includes('_label') == false){

		        			if(key == 'background'){
		        				//var stylename = key.replace('_', '-');
		        				if(styles[i][key].value[0] == 'background-image'){
		        					style += ''+styles[i][key].value[0]+': url('+styles[i][key].value[1]+'); '
		        				}else{
		        					style += ''+styles[i][key].value[0]+': '+styles[i][key].value[1]+'; '
		        				}
		        				style += 'background-size: '+styles[i][key].size+'; '
		        				style += 'background-position: '+styles[i][key].position+'; '
		        				style += 'background-repeat: '+styles[i][key].repeat+'; '
		        			}
		        			else if(styles[i][key].type == 'numbergroup'){
		        				//var stylename = key.replace('_', '-');
		        				for(var numgroupkey in styles[i][key]){
		        					if((numgroupkey !== 'parentlabel') || (numgroupkey !== 'type')){
		        						if((key.includes('_radius') == false) && (key.includes('border') == true)){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey]+'px '+styles[this.filterStylesKey(styles, 'border_style')]["border_style"].value+' '+styles[this.filterStylesKey(styles, 'border_colour')]["border_colour"].value+' !important; '

		        						}else if(key.includes('_radius') == false){

		        							style += ''+key+'-'+numgroupkey.replace('_','-')+': '+styles[i][key][numgroupkey] ? styles[i][key][numgroupkey]+'px !important; ' : 'auto !important; '
		        						
		        						}else{
		        						
		        							style += ''+key.replace('_radius', '')+'-'+numgroupkey.replace('_','-')+'-radius: '+styles[i][key][numgroupkey]+'px !important; '
		        						
		        						}
		        					}
		        				}
		        			}
		        			else if(styles[i][key].type == 'range'){
		        					if('units' in styles[i][key]){
		        						//console.log(styles[i][key].value);
		        						if(styles[i][key].value !== null){
			        						var stylename = key.replace('_', '-');
					        				style += ''+stylename+': '+parseFloat(styles[i][key].value)+''+styles[i][key].units+'; '; 
				        					}
		        					 }else{
		        					 		style += ''+stylename+': '+styles[i][key].value+'; '
		        					 }

		        			}else{
		        				var stylename = key.replace('_', '-');
		        				stylename = stylename.replace('colour', 'color');
		        				if(styles[i][key].value.length !== 0){
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}else{
		        					style += ''+stylename+': '+styles[i][key].value+' !important; '
		        				}
		        			}
		        		}
	        		}
	        	}
	        }
	        	return style;
	     },
	        compileElementStyles(content, fieldname, types){
			var style = '';
				if(types.includes('background') == true){
					if(fieldname !== 'column'){
				  		style += 'background-color:'+content[this.filterStylesKey(content, fieldname+'_colour')][fieldname+'_colour'].value+' !important;'
				  	}else{
				  		style += 'background-color:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'background_colour')].background_colour.value+' !important;'
				  	}
				}
				if(types.includes('color') == true){
					if(fieldname == 'text'){
						style += 'color:'+content[this.filterStylesKey(content, fieldname+'_colour')][fieldname+'_colour'].value+' !important; '
					}else{
						style += 'color:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'text_colour')].text_colour.value+' !important;'
					}
				}
				if(types.includes('border') == true){
					if(fieldname !== 'column'){
						style += 'border-radius:'+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_left+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].top_right+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_right+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' '+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].bottom_left+content[this.filterStylesKey(content, fieldname+'_border_radius')][fieldname+'_border_radius'].units +' !important;'
						style += ' border-left:'+content[this.filterStylesKey(content, fieldname+'_border')][fieldname+'_border'].left+'px !important; border-right: '+content[this.filterStylesKey(content, fieldname+'_border')][fieldname+'_border'].right+'px !important; border-top: '+content[this.filterStylesKey(content, fieldname+'_border')][fieldname+'_border'].top+'px !important; border-bottom: '+content[this.filterStylesKey(content, fieldname+'_border')][fieldname+'_border'].bottom+'px !important;'

						style += 'border-style: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_style')][fieldname+'_border_style'].value+' !important; ';
						style +=  ' border-color: '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, fieldname+'_border_colour')][fieldname+'_border_colour'].value+' !important;' ;
					}else{
						style +=  'border-color: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border_colour')].border_colour.value+' !important;'

						style += 'border-style:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border_style')].border_style.value+' !important;'

						style += 'border-radius:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_left+' '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.top_right+' '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_right+' '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border_radius')].border_radius.bottom_left+' !important;'
						style += 'border-left:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border')].border.left+'px !important; border-right: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border')].border.right+'px !important; border-top: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border')].border.top+'px !important; border-bottom: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'border')].border.bottom+'px !important;'
					}

				}
				if(types.includes('margin') == true){
					if(fieldname !== 'column'){
					style +=  'margin-left:'+content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].left !== '' ? content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].left +'px !important;' : 'auto !important';
					style +=  'margin-right: '+content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].right !== '' ? content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].right+'px !important;' : 'auto !important';
					style +=  'margin-top: '+content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].top !== '' ? content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].top+'px !important;' : 'auto !important';
					style +=  'margin-bottom: '+content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].bottom !== '' ? content[this.filterStylesKey(content, fieldname+'_margin')][fieldname+'_margin'].bottom+'px !important;' : 'auto !important';
					}else{
					style +=  'margin-left:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'margin')]['margin'].left+'px !important;'
					style +=  'margin-right: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'margin')]['margin'].right+'px !important;'
					style +=  'margin-top: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'margin')]['margin'].top+'px !important;'
					style +=  'margin-bottom: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'margin')]['margin'].bottom+'px !important;'
					}
				}
				if(types.includes('padding') == true){
					if(fieldname !== 'column'){
					style +=  'padding-left:'+content[this.filterStylesKey(content, fieldname+'_padding')][fieldname+'_padding'].left+'px !important;'
					style +=  'padding-right: '+content[this.filterStylesKey(content, fieldname+'_padding')][fieldname+'_padding'].right+'px !important;'
					style +=  'padding-top: '+content[this.filterStylesKey(content, fieldname+'_padding')][fieldname+'_padding'].top+'px !important;'
					style +=  'padding-bottom: '+content[this.filterStylesKey(content, fieldname+'_padding')][fieldname+'_padding'].bottom+'px !important;'
					}else{
						style +=  'padding-left:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'padding')]['padding'].left+'px !important;'
					style +=  'padding-right: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'padding')]['padding'].right+'px !important;'
					style +=  'padding-top: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'padding')]['padding'].top+'px !important;'
					style +=  'padding-bottom: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'padding')]['padding'].bottom+'px !important;'
					}
				}
				if(types.includes('size') == true){
					if(fieldname !== 'column'){
					style +=  'height:'+content[this.filterStylesKey(content, fieldname+'_height')][fieldname+'_height'].value+content[this.filterStylesKey(content, fieldname+'_height')][fieldname+'_height'].units+' !important;';
					style +=  'max-height:'+content[this.filterStylesKey(content, fieldname+'_max_height')][fieldname+'_max_height'].value+content[this.filterStylesKey(content, fieldname+'_max_height')][fieldname+'_max_height'].units+' !important;';
					style +=  'width:'+content[this.filterStylesKey(content, fieldname+'_width')][fieldname+'_width'].value+content[this.filterStylesKey(content, fieldname+'_width')][fieldname+'_width'].units+' !important;'
					style +=  'max-width:'+content[this.filterStylesKey(content, fieldname+'_max_width')][fieldname+'_max_width'].value+content[this.filterStylesKey(content, fieldname+'_max_width')][fieldname+'_max_width'].units+' !important;'
					}else{
					style +=  'height:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'height')]['height'].value+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'height')]['height'].units+' !important;';
					style +=  'max-height:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].value+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'max_height')]['max_height'].units+' !important;';
					style +=  'width:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'width')]['width'].value+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'width')]['width'].units+' !important;'
					style +=  'max-width:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].value+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'max_width')]['max_width'].units+' !important;'
					} 
				}
				
				if(types.includes('text') == true){
				  style += 'font-family: \"'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.family+'\", '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'font_family')].font_family.value.category+' !important; font-size:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'font_size')].font_size.value+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'font_size')].font_size.units+' !important; text-transform: '+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'text_transform')].text_transform.value+'!important;';
				}

				if(types.includes('text_alignment') == true){
					style += 'text-align:'+content[this.filterStylesKey(content, fieldname)][fieldname].subfields[this.filterStylesKey(content[this.filterStylesKey(content, fieldname)][fieldname].subfields, 'text_align')].text_align.value+' !important;'
				}

				if(types.includes('horizontal_alignment') == true){
					style += 'text-align:' +content[this.filterStylesKey(content, 'horizontal_align')]['horizontal_align'].value+''
				}

				if(types.includes('opacity') == true){
					style += 'opacity:'+content[this.filterStylesKey(content, fieldname+'_opacity')][fieldname+'_opacity'].value+' !important;'
				}

				return style;
			},
	        compileFontStyles(){
				var style = '';
				for(var key in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs){
					
						
						for(var skey in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key]){
								style += '#'+this.id+'-overlay_description '+skey+'{';
								for(var subskey in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey]){
									for(var subsubskey in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey]){
									if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey].value !== ''){
										if((this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey][subsubskey].value !== '') && (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey][subsubskey].value !== null)){
											if(subsubskey !== 'font_family'){
												style += ''+subsubskey.replaceAll('_', '-')+': '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey][subsubskey].value+''+ (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey][subsubskey].units !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey][subsubskey].units : '') +' !important; '
											}else{
												style += 'font-family: \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey][subsubskey].value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_description')].overlay_description.subtabs[key][skey][subskey][subsubskey].value.category+' !important; '
											}
										}

									}
								}
							}
						}
						style += '}\n';
				}
				document.getElementById('customCss').innerHTML += style;
				
				return style;
			},
			compileTitleStyles(){
				var style = '';
				for(var key in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs){
					
						
						for(var skey in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key]){
								style += '#'+this.id+'-overlay_title '+skey+'{';
								for(var subskey in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey]){
									for(var subsubskey in this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey]){
									if(this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey].value !== ''){
										if((this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey][subsubskey].value !== '') && (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey][subsubskey].value !== null)){
											if(subsubskey !== 'font_family'){
												style += ''+subsubskey.replaceAll('_', '-')+': '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey][subsubskey].value+''+ (this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey][subsubskey].units !== null ? this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey][subsubskey].units : '') +' !important; '
											}else{
												style += 'font-family: \"'+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey][subsubskey].value.family+'\", '+this.$attrs.content[this.filterStylesKey(this.$attrs.content, 'overlay_title')].overlay_title.subtabs[key][skey][subskey][subsubskey].value.category+' !important; '
											}
										}

									}
								}
							}
						}
						style += '}\n';
				}
				document.getElementById('customCss').innerHTML += style;
				
				return style;
			}
		}
	}

</script>